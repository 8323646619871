import {
  BaseSyntheticEvent,
  FC,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { uploadFile } from '../../utils';
import { StatusStep, DocumentStatus, DocumentType } from '../../enums';
import {
  ButtonSetting,
  Details,
  UploadRequest,
  SignatureInfo,
} from '../../interfaces';
import {
  buttonsMap,
  titleMap,
  getPlaceholderComp,
  getErrorText,
  SIGNATURE_LOCATION,
} from './constants';
import { FileType } from '../../enums/file-type';
import { StatusConfig } from '../../interfaces/status-config';
import { StatusDetails } from '../StatusDetails/StatusDetails';
import { Spinner } from '../Spinner/Spinner';
import { Wizard } from '../Wizard/Wizard';
import { useDocument } from '../../hooks';
import { AuthContext } from '../../store/AuthContext';
import { getFullName, toJSONLocal } from '../../utils/utils';
import { DEFAULT_ERROR_MESSAGE } from '../../constants';

import SuccessSignIcon from '../../assets/images/icons/success-sign-icon.svg';

const DEFAULT_UPLOAD_PAYLOAD = {
  fileName: '',
  signatureInfo: {
    positionX: 0,
    positionY: 0,
    image: '',
    writeText: true,
    additionalTextFields: [],
  },
  content: '',
};

export const Sign: FC = memo(() => {
  const navigate = useNavigate();
  const { claims } = useContext(AuthContext);
  const [step, setStep] = useState(0);
  const [viewDocStep, setViewDocStep] = useState<StatusStep>(StatusStep.FIRST);
  const [uploadPayload, setUploadPayload] = useState<UploadRequest>(
    DEFAULT_UPLOAD_PAYLOAD,
  );
  const [documentId, setDocumentId] = useState('');
  const [count, setCount] = useState(0);
  const [file, setFile] = useState<FileType>(null);
  const [details, setDetails] = useState<Details>({} as Details);
  const [otp, setOtp] = useState<string | null>(null);
  const [signatureInfo, setSignatureInfo] = useState<SignatureInfo>({
    signedBy: '',
    issuer: '',
    location: '',
    date: '',
  });
  const [page, setPage] = useState<number>(1);
  const [styleSignature, setStyleSignatue] = useState<boolean>(false);
  const {
    uploadDocument,
    renewOtpCode,
    getDocumentInfo,
    getDocumentById,
    cancelDocumentSign,
    downloadFile,
    getCertificatesIssuer,
  } = useDocument();
  let imageSign = document.getElementById('signImage');
  let pdfDocument = document.getElementsByClassName('react-pdf__Document');
  let interval: NodeJS.Timeout;

  const handleBackBtn = () => {
    clearTimeout(interval);
    setCount(0);
    if (!step) {
      navigate(-1);
    } else {
      setStep(step - 1);
      setViewDocStep(step === 1 ? StatusStep.FIRST : StatusStep.UPLOADED);
    }
  };

  const isSigned = (status: string): boolean => {
    return status.toLowerCase() === DocumentStatus.SIGNED.toLowerCase();
  };

  const cancelDocSign = () => {
    setCount(0);
    setStep(3);
    setViewDocStep(StatusStep.ERROR);
    setDetails((currentState) => ({
      ...currentState,
      status: DocumentStatus.CANCELED,
    }));
  };

  const getDocumentInformation = (id = ''): Promise<DocumentStatus | void> => {
    return getDocumentInfo(id || documentId)
      .then(({ data }) => {
        if (isSigned(data.status)) {
          setCount(0);
          setStep(3);
          setDetails((currentState) => ({
            ...currentState,
            status: data.status,
            fileName: data.fileName,
            signer: getFullName(claims),
            btn: [],
          }));
          setViewDocStep(StatusStep.SUCCESS);
          getDocumentById(data.id).then((signed) => setFile(signed.data));
        } else if (
          data.status.toLowerCase() === DocumentStatus.CANCELED.toLowerCase()
        ) {
          cancelDocSign();
        }
        return data.status;
      })
      .catch(() => cancelDocSign());
  };

  const statusMap = new Map<StatusStep, StatusConfig>([
    [StatusStep.UPLOADED, getPlaceholderComp()],
    [StatusStep.SIGN, getPlaceholderComp()],
    [
      StatusStep.PROGRESS,
      {
        Component: StatusDetails,
        SpinnerComponent: Spinner,
        title: 'Waiting for confirmation...',
        otp: otp ?? '',
        count,
        description:
          'A notification was sent to your Mobile Phone. Please tap the notification or open ZamMobile app, and complete signing.',
      },
    ],
    [
      StatusStep.TIME_OUT,
      {
        Component: StatusDetails,
        SpinnerComponent: Spinner,
        title: 'Waiting for confirmation...',
        otp: otp ?? '',
        count,
        btn: [
          {
            label: 'Generate Now',
            class: 'btn-primary',
            onClick: () => {
              renewOtpCode(documentId)
                .then(({ data }) => {
                  setOtp(data);
                  setCount(1);
                  setViewDocStep(StatusStep.PROGRESS);
                })
                .catch((error) => console.error(error));
            },
          },
        ],
        btnDescription: 'The time is out, please generate a new One Time Code',
      },
    ],
    [
      StatusStep.SUCCESS,
      {
        Component: StatusDetails,
        img: {
          url: SuccessSignIcon,
          alt: 'SuccessSignIcon',
        },
        title: 'The document was successfully signed',
        showDetails: true,
        btn: [
          {
            label: 'Download',
            class: 'btn-outline-primary',
            iconClass: 'icon-download-key',
            onClick: () => {
              downloadFile(documentId, details.fileName);
              toast.success('The document has been downloaded.');
            },
          },
        ],
      },
    ],
    [StatusStep.ERROR, getErrorText()],
  ]);

  const { Component, ...configStatus } = statusMap.get(viewDocStep) || {};

  const handleSignBtn = () => {
    setViewDocStep(StatusStep.PROGRESS);
    const documentPayload = {
      ...uploadPayload,
      signatureInfo: {
        ...uploadPayload.signatureInfo,
        image: uploadPayload.signatureInfo.image.length
          ? uploadPayload.signatureInfo.image
          : null,
        page,
        positionX:
          uploadPayload.signatureInfo.image.length &&
          uploadPayload.signatureInfo.writeText
            ? uploadPayload.signatureInfo.positionX
            : null,
        positionY:
          uploadPayload.signatureInfo.image.length &&
          uploadPayload.signatureInfo.writeText
            ? uploadPayload.signatureInfo.positionY
            : null,
      },
    };
    uploadDocument(documentPayload)
      .then(({ data }) => {
        setOtp(data.otp);
        setDocumentId(data.documentId);
        setStep(2);
        getDocumentInformation(data.documentId).then(
          (status: DocumentStatus | void) => {
            if (!isSigned(status as DocumentStatus)) setCount(count + 1);
          },
        );
      })
      .catch(() => cancelDocSign());
  };

  const resetSteps = () => {
    setStep(0);
    setCount(0);
    clearTimeout(interval);
    setViewDocStep(StatusStep.FIRST);
    setUploadPayload(DEFAULT_UPLOAD_PAYLOAD);
    setStyleSignatue(true);
  };

  const handleDownload = () => {
    downloadFile(documentId, uploadPayload.fileName);
  };

  const onChangeStep = (value: StatusStep) => {
    setViewDocStep(value);
    if (value === StatusStep.UPLOADED) {
      setStep(1);
    } else if ([StatusStep.SUCCESS, StatusStep.ERROR].includes(value)) {
      setStep(2);
    }
  };

  const handleCancelBtn = () => {
    clearTimeout(interval);
    cancelDocumentSign(documentId)
      .then(() => cancelDocSign())
      .catch(() => cancelDocSign());
  };

  const elementsOverlap = (firstEl: Element, secondEl: Element) => {
    const domRect1 = firstEl.getBoundingClientRect();
    const domRect2 = secondEl.getBoundingClientRect();

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  };

  const checkOverlap = () => {
    if (imageSign || pdfDocument) {
      imageSign = document.getElementById('signImage');
      pdfDocument = document.getElementsByClassName('react-pdf__Document');
    }
    onChangeStep(
      elementsOverlap(imageSign as Element, pdfDocument[0])
        ? StatusStep.SIGN
        : StatusStep.UPLOADED,
    );
  };

  const checkOverlapBoxSignature = () => {
    const imageSignElement = document.getElementById('signImage');
    const imageSignRect = imageSignElement?.getBoundingClientRect() as DOMRect;
    const documentRect = document
      .querySelector('.react-pdf__Document')
      ?.getBoundingClientRect() as DOMRect;
    const overlaped =
      imageSignRect.x >= documentRect.x &&
      imageSignRect.x <= documentRect.x + documentRect.width &&
      imageSignRect.y >= documentRect.y &&
      imageSignRect.y <= documentRect.y + documentRect.height;
    setStyleSignatue(overlaped);

    return overlaped;
  };

  const handleDrag = (event: MouseEvent) => {
    const { type } = event;
    if (type === 'mousemove') {
      setStyleSignatue(true);
      checkOverlap();
    } else if (type === 'mouseup') {
      const { x: parentX = 0, y: parentY = 0 } =
        document
          .querySelector('.react-pdf__Document')
          ?.getBoundingClientRect() || {};
      const { x: childX = 0, y: childY = 0 } =
        document.querySelector('#signImage')?.getBoundingClientRect() || {};
      const positionX = Math.ceil(childX) - Math.ceil(parentX);
      const positionY = Math.ceil(childY) - Math.ceil(parentY);
      checkOverlapBoxSignature();
      setUploadPayload({
        ...uploadPayload,
        signatureInfo: {
          ...uploadPayload.signatureInfo,
          positionX: positionX < 0 ? 0 : positionX,
          positionY: positionY < 0 ? 0 : positionY,
        },
      });
    }
  };

  const onChange = (e: BaseSyntheticEvent): void => {
    uploadFile(e, (fileDetails: FileType, uploadedFile: File) => {
      setFile(fileDetails);
      setOtp(null);
      setUploadPayload({
        ...uploadPayload,
        content: (fileDetails as string).split(',')[1],
        fileName: uploadedFile.name,
      });
      onChangeStep(StatusStep.UPLOADED);
    });
  };

  const onLoadError = () => {
    alert('Only PDF files are supported');
    setFile(null);
    onChangeStep(StatusStep.FIRST);
  };

  useEffect(() => {
    interval = setTimeout(() => {
      if (count && count < 10) {
        getDocumentInformation().then((status: DocumentStatus | void) => {
          if (!isSigned(status as DocumentStatus)) setCount(count + 1);
        });
      }
      clearTimeout(interval);
    }, 10000);

    if (count === 10) {
      setCount(0);
      renewOtpCode(documentId)
        .then(({ data }) => {
          setOtp(data);
          setCount(1);
          setViewDocStep(StatusStep.PROGRESS);
        })
        .catch((error: AxiosError) => {
          toast.error(error.message || DEFAULT_ERROR_MESSAGE);
        });
    }
  }, [count]);

  useEffect(() => {
    getCertificatesIssuer().then(({ data: { issuer } }) => {
      setSignatureInfo((currentState) => ({
        ...currentState,
        signedBy: (claims?.firstName || '') + ' ' + (claims?.lastName || ''),
        issuer,
        location: SIGNATURE_LOCATION,
        date: toJSONLocal(new Date()) + ' UTC+0',
      }));
    });
  }, []);

  return (
    <div className={viewDocStep === StatusStep.SIGN ? 'signed-container' : ''}>
      <Wizard
        title="Sign Document"
        step={step}
        section={DocumentType.SIGN}
        subtitle={titleMap.get(viewDocStep) as string}
        component={() => {
          return (
            Component && (
              <Component
                handleDrag={handleDrag}
                setUploadPayload={setUploadPayload}
                config={configStatus}
                details={details}
                count={count}
                uploadPayload={uploadPayload}
                signatureInfo={signatureInfo}
                styleSignature={styleSignature}
                checkOverlapBoxSignature={checkOverlapBoxSignature}
              />
            )
          );
        }}
        buttons={
          (buttonsMap.get(viewDocStep) as ({}) => ButtonSetting[])({
            handleBackBtn,
            handleSignBtn,
            resetSteps,
            handleDownload,
            handleCancelBtn,
          }) as ButtonSetting[]
        }
        viewDocStep={viewDocStep}
        onChange={onChange}
        file={file}
        onLoadError={onLoadError}
        setPage={setPage}
      />
    </div>
  );
});
