import { StatusStep } from '../../enums';
import { ButtonSetting } from '../../interfaces';
import { DocumentPlaceholder } from '../DocumentPlaceholder/DocumentPlaceholder';
import { StatusDetails } from '../StatusDetails/StatusDetails';
import SuccessSignIcon from '../../assets/images/icons/success-sign-icon.svg';
import FailureSignIcon from '../../assets/images/icons/failure-sign-icon.svg';

export const SIGNATURE_LOCATION = 'ZamSign';

export const titleMap = new Map<StatusStep, string>([
  [StatusStep.FIRST, 'Upload the PDF file for signing.'],
  [
    StatusStep.UPLOADED,
    'Specify the position of the signature on the document',
  ],
  [StatusStep.SIGN, 'Specify the position of the signature on the document'],
  [StatusStep.PROGRESS, 'Waiting for confirmation'],
  [StatusStep.TIME_OUT, 'Waiting for confirmation'],
  [StatusStep.SUCCESS, 'View Signed Document'],
]);

export const getPlaceholderComp = () => ({ Component: DocumentPlaceholder });
export const getSuccessText = () => ({
  Component: StatusDetails,
  img: {
    url: SuccessSignIcon,
    alt: 'SuccessSignIcon',
  },
  title: 'The document was successfully signed',
  showDetails: true,
});

export const getErrorText = (title?: string, description?: string) => ({
  Component: StatusDetails,
  img: {
    url: FailureSignIcon,
    alt: 'FailureSignIcon',
  },
  title: title || 'Signature Canceled',
  description,
});

export const buttonsMap = new Map<
  StatusStep,
  ({
    handleBackBtn,
    handleSignBtn,
    resetSteps,
    handleDownload,
    handleCancelBtn,
  }: {
    handleBackBtn: () => void;
    handleSignBtn: () => void;
    resetSteps: () => void;
    handleDownload: () => void;
    handleCancelBtn: () => void;
  }) => ButtonSetting[]
>([
  [
    StatusStep.FIRST,
    ({ handleBackBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled: true,
          label: 'Sign',
          class: 'btn-primary',
        },
      ];
    },
  ],
  [
    StatusStep.UPLOADED,
    ({ handleBackBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled: true,
          label: 'Sign',
          class: 'btn-primary',
          onClick: () => console.log('No action'),
        },
      ];
    },
  ],
  [
    StatusStep.SIGN,
    ({ handleBackBtn, handleSignBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled: false,
          label: 'Sign',
          class: 'btn-primary',
          onClick: handleSignBtn,
        },
      ];
    },
  ],
  [
    StatusStep.PROGRESS,
    ({ handleBackBtn, handleCancelBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          label: 'Cancel',
          class: 'btn-primary',
          onClick: handleCancelBtn,
        },
      ];
    },
  ],
  [
    StatusStep.TIME_OUT,
    ({ handleBackBtn, handleCancelBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          label: 'Cancel',
          class: 'btn-primary',
          onClick: handleCancelBtn,
        },
      ];
    },
  ],
  [
    StatusStep.SUCCESS,
    ({ resetSteps }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: resetSteps,
        },
      ];
    },
  ],
  [
    StatusStep.ERROR,
    ({ resetSteps }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: resetSteps,
        },
      ];
    },
  ],
]);
