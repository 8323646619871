import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../../store/AuthContext';
import { DocumentStatus, Pages } from '../../enums';
import {
  getSearchParams,
  handleError,
  updateSearchParams,
} from '../../utils/utils';
import {
  Column,
  HistoryFilters,
  HistoryResponse,
  ProfileHistory,
  Props,
  RenderValue,
} from '../../interfaces';
import { useDocument, useTableActions } from '../../hooks';
import {
  ConfirmModal,
  FilterTable,
  Table,
  HistoryDetails,
  ActionButtons,
} from '../';
import {
  COLUMNS,
  getFilterItemsByPage,
  getHistoryFilters,
} from '../../utils/constants';
import {
  CONFIG_MODAL_MAP,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_FILTERS,
} from '../../constants';
import { Selection } from '../FilterTable/FilterTableSelection';
import { Actions } from '../../enums/history-key';

const TABLE_NAME = 'documents-archive-table';

export const DocumentsArchive = memo(() => {
  const { claims } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<HistoryFilters>(DEFAULT_FILTERS);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [list, setList] = useState<ProfileHistory[]>([]);
  const [documentsCount, setDocumentsCount] = useState<number>(1);
  const [selectedItem, setSelectedItem] = useState<ProfileHistory>({
    created: '',
    documentId: '',
    fileName: '',
    signed: '',
    status: DocumentStatus.CANCELED,
  });
  const navigate = useNavigate();
  const { mode, activeItem, onActionClick, onClose } =
    useTableActions<ProfileHistory>();
  const { downloadFile, getHistory, deleteMultipleDocuments, restoreDocument } =
    useDocument();

  const getHistoryList = () => {
    const params = getSearchParams(searchParams) as Props;
    const updatedFilter = !firstLoad
      ? { ...filters, ...getHistoryFilters(params) }
      : filters;
    getHistory({
      ...updatedFilter,
      isArchived: true,
    })
      .then(({ data }: { data: HistoryResponse }) => {
        setList(data.items);
        setDocumentsCount(data.count);
        setFirstLoad(true);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const onViewHandler = useCallback((item: ProfileHistory) => {
    setSelectedItem(item);
  }, []);

  const onDownloadHandler = useCallback((item: ProfileHistory) => {
    toast.success('The document has been downloaded.');
    downloadFile(item.documentId, item.fileName);
  }, []);

  const onSubmitFilterHandler = useCallback((values: Props) => {
    setFilters({
      ...filters,
      ...getHistoryFilters(values),
    });
  }, []);

  const onResetHandler = useCallback(() => {
    setFilters(DEFAULT_FILTERS);
  }, []);

  const buttonActions = useMemo(
    () => [
      {
        name: 'view',
        icon: 'icon-eye',
        tooltip: 'View Document',
        onClick: (item: ProfileHistory) => onViewHandler(item),
      },
      {
        name: 'download',
        icon: 'icon-download-key',
        tooltip: 'Download Document',
        onClick: onDownloadHandler,
      },
      {
        name: 'restore',
        icon: 'icon-restore',
        tooltip: 'Restore Document',
        onClick: (item: ProfileHistory) => onActionClick(Actions.Restore, item),
      },
      {
        name: 'delete',
        icon: 'icon-delete text-error',
        tooltip: 'Delete Document',
        onClick: (item: ProfileHistory) => onActionClick(Actions.Delete, item),
      },
    ],
    [],
  );

  const columnsTable: Column[] = useMemo(
    () => [
      ...COLUMNS,
      {
        headerText: 'Actions',
        field: 'action',
        render: (_: RenderValue, record: any) => (
          <div className="d-flex align-items-center table-text p1">
            <div className="d-flex">
              <ActionButtons item={record} buttons={buttonActions} />
            </div>
          </div>
        ),
      },
    ],
    [],
  );
  const updateFilterHandler = useCallback(() => {
    const params = getSearchParams(searchParams) as Props;
    setFilters({
      ...filters,
      ...getHistoryFilters(params),
    });
  }, [searchParams, filters]);

  const onDeleteMultipleDocumentsHandler = useCallback((payload: unknown) => {
    return deleteMultipleDocuments(
      (payload as Props[]).map((item) => item.documentId),
    )
      .then(() => {
        setFilters(DEFAULT_FILTERS);
        const newParams = updateSearchParams(searchParams, DEFAULT_FILTERS);
        navigate('?' + newParams);
        toast.success('The document(s) has been deleted.');
        updateFilterHandler();
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message ||
            (error?.response?.data as string) ||
            DEFAULT_ERROR_MESSAGE,
        );
      });
  }, []);

  const selection = useMemo(
    () => ({
      name: TABLE_NAME,
      onDelete: (items?: unknown[]) => onDeleteMultipleDocumentsHandler(items),
      icon: 'icon-delete',
      text: 'Delete',
    }),
    [],
  );

  const onDeleteHandler = useCallback(() => {
    deleteMultipleDocuments([activeItem?.documentId as string])
      .then(() => {
        toast.success('The document(s) has been deleted.');
        updateFilterHandler();
        onClose();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  }, [activeItem]);

  const onRestoreHandler = useCallback(() => {
    restoreDocument(activeItem?.documentId as string)
      .then(() => {
        toast.success('The document has been restored.');
        updateFilterHandler();
        onClose();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  }, [activeItem]);

  const onConfirm = useCallback(() => {
    mode === Actions.Delete && onDeleteHandler();
    mode === Actions.Restore && onRestoreHandler();
  }, [mode]);

  const onToggleHandler = useCallback(() => {
    onClose();
  }, []);

  useEffect(() => {
    getHistoryList();
  }, [filters, claims?.name]);

  return (
    <div className="d-block section-page my-1 mx-1">
      <div className="header-section d-flex align-items-center">
        <div className="py-2 px-4">
          <p className="open-sans-semibold m-0">
            <i className="align-middle icon-documents me-2"></i>Documents
          </p>
        </div>
      </div>
      <div className="table-container col-12 table-responsive p-3">
        <div className="bg-white inner-section">
          <FilterTable
            items={getFilterItemsByPage(Pages.DocumentsArchive)}
            onSubmit={onSubmitFilterHandler}
            onReset={onResetHandler}
            selection={selection as Selection}
          />
          <Table
            name={TABLE_NAME}
            columns={columnsTable}
            data={list}
            pagination={{ totalItems: documentsCount }}
            onSubmit={onSubmitFilterHandler}
            allowRowSelect={true}
            selectKey="documentId"
            activeId={activeItem?.documentId}
          />
          {mode !== Actions.View && (
            <ConfirmModal
              setConfirmationDialog={onToggleHandler}
              deleteHandler={onConfirm}
              config={CONFIG_MODAL_MAP.get(mode)}
              mode={mode}
            />
          )}
        </div>
      </div>
      <HistoryDetails
        historyItem={selectedItem}
        documentHasChanged={getHistoryList}
        page={Pages.DocumentsArchive}
      />
    </div>
  );
});
