import React from 'react';
import { PixelCrop } from 'react-image-crop';
import PlaceholderPlugIcon from '../../../../assets/images/icons/placeholder-plug-icon.png';

interface PreviewCompletedCropProps {
  previewCanvasRef: React.RefObject<HTMLCanvasElement> | null;
  completedCrop: PixelCrop | undefined;
}

export const PreviewCompletedCrop = ({
  previewCanvasRef,
  completedCrop,
}: PreviewCompletedCropProps) => {
  return (
    <div
      className="row p-0 m-0 border-3 border-primary rounded-3"
      style={{ borderStyle: 'solid' }}
    >
      <div className="col-4 d-flex justify-content-center align-items-center">
        <canvas
          ref={previewCanvasRef}
          style={{
            objectFit: 'contain',
            width: completedCrop?.width,
            height: completedCrop?.height,
            maxWidth: '167px',
            maxHeight: '170px',
          }}
        />
      </div>
      <div className="col-8 p-4 d-flex justify-content-center">
        <img src={PlaceholderPlugIcon} alt="Preview" />
      </div>
    </div>
  );
};
